@import "https://use.fontawesome.com/ede1f9233a.css";
@import url('https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i');

@font-face {
	font-family: facit;
	src: url(/fonts/1/facit-regular.ttf);
}
@font-face {
	font-family: dinpro;
	src: url(/fonts/1/dinpro-medium.ttf);
}

.folder li { list-style:none; }
.folder a img {
	height:auto;
	margin-right:10px;
	width:25px;
}
.folder {
	background:url("/img/ledennet/folder.png");
	background-repeat:no-repeat;
	background-size:25px;
	list-style:none;
	padding-left:30px;
}
.folderTrigger {
	display: block;
	height: 35px;
}
.folder ul,
.treeview {
	margin:0;
}
.folderTrigger {
	cursor: pointer;
}
.folder ul {
	display:none;
	margin-bottom:10px;
	li.docu {
		margin:0 0 .5rem 0;
	}
	&.open {
		display:block;
	}
}
.treeview {
	background:#efefef;
	padding:1rem 0.5rem;
	li {
		list-style:none;
	}
}
.treeview a img {
	height: auto;
	margin-right: 10px;
	width: 25px;
}
.folderTrigger:hover {
	text-decoration: underline;
}

h1,
h2,
h3 {
	text-transform: uppercase;
	letter-spacing:.75px;
	line-height:1.15em;
}

.header.variant1 .bottomWrapper .top-bar li.active > a {
	font-weight: 700;
}

.top-bar-section .has-dropdown.hover > .dropdown,
.top-bar-section .has-dropdown.not-click:hover > .dropdown {
	box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.25);
}
.header.variant1 .bottom .logo img {
	margin-top:0;
}

.header.variant1 .top-bar-section ul.dropdown li:not(.last) > a {
	padding-bottom:0!important;
}
.slider.variant1 .slide .caption h3,
.slider.variant1 .slide .caption h3 a {
	font-size:2rem;
	font-weight: 700;
	text-shadow:0 0 10px #333;
	text-transform: none;
}

#Block_EditProfile_SN_Prive_RadioButton_Wrapper,
#Block_SubScribeNewsletter_Block_SubScribeNewsletter_lblvoorletters,
#Block_SubScribeNewsletter_Block_SubScribeNewsletter_tbvoorletters {
	display:none;
}
.meetingAgenda {
	display:block;
	.showdocuments {
		padding:0 0 0 1rem;
	}
	ul {
		display:none;
		&.open {
			display:block;
		}
	}
}
.mySubscribedMeetings.variant1 ul li,
.overzicht.kort.agenda article {
	border-bottom:1px solid #efefef;
	&:last-child {
		border-bottom:none;
	}
	.status {
		float:right;
	}
}
.block.meetings.variant1 {
	h5 {
		display:inline;
		padding-left:1rem;
	}
}
.password_complexity_instruction {
	float:left;
}

.comment {
	float: left;
	font-size: 1.25rem;
	margin: 0 0 1rem 0;
	width: 100%;
}

.myPersonalInformation.variant1 .add_mutation_link,
.myPersonalInformation.variant1 .SN_Prive #Block_EditProfile_editPersonAnchor,
.myPersonalInformation.variant1 .SN_Prive #Block_EditProfile_editPersonAnchor .SN_Prive_DataItem,
.myPersonalInformation.variant1 .SN_Prive #Block_EditProfile_SN_PriveData_Email,
#Block_EditProfile_SN_NietTonen_RadioButton_Wrapper,
#Block_EditProfile_SN_Medewerkers .mutation_link,
#Block_EditProfile_SN_Medewerkers.SN_Medewerkers span,
#Block_EditProfile_SN_Medewerkers_Label,
#Block_EditProfile_SN_FormField_Submit,
.myPersonalInformation.variant1 #Block_EditProfile_SN_PriveData_GeboorteDatum,
.myPersonalInformation.variant1 #Block_EditProfile_SN_PriveData_Adres,
.myPersonalInformation.variant1 #Block_EditProfile_SN_PriveData_Postcode,
.myPersonalInformation.variant1 #Block_EditProfile_SN_PriveData_Plaats,
.myPersonalInformation.variant1 #Block_EditProfile_SN_PriveData_Land,
.myPersonalInformation.variant1 #Block_EditProfile_SN_PriveData_Telefoon,
.myPersonalInformation.variant1 #Block_EditProfile_SN_PriveData_Mobiel,
.block.personPersonalInformation.variant1 .SN-Block-PersonalInformation-Title.SN-Block-PersonalInformation-Prive,
.block.personPersonalInformation.variant1 .SN-Block-PersonalInformation-Item.SN-Block-PersonalInformation-Item-Prive-Email,
.block.personPersonalInformation.variant1 .SN-Block-PersonalInformation-Title.SN-Block-PersonalInformation-Medewerker {
	display:none;
}

.row.componentPersonInfo .medium-4 label,
.row.componentPersonInfo .medium-8 .select2-choice.ui-select-match,
#E1Profile.ui-select-container,
.row.componentPersonInfo .medium-8.small-12.columns > a {
	display:none;
}

h4.formTitle:after {
	content:"Wanneer uw gegevens gewijzigd zijn graag de wijzigingen doorgeven aan het secretariaat van AZN: secretariaatazn@ambulancezorg.nl of 088-3838200.";
	display:block;
	width:100%;
}
.changepassword_successmessage{font-weight:700;font-size:1.25rem;color:#f00;text-transform:uppercase;}

.meetingSubscription.ng-scope {
	h4.formTitle::after {
		content:"";
	}
	.additionalProfileData.panel.ng-scope {
		display:none;
	}
}

#Block_EditProfile_SN_Medewerkers,
#Block_1_Show_Person_Personal_Information {
	display:none;
}
#cookieMsg{display:none}